import {
  BenefitType,
  ProjectEffectRealisation,
  ProjectEffectRealisationPerYear,
  ProjectGoal,
  ProjectGoalBenefit,
} from 'models';

/**
 * Merge benefits with efficiency goal benefits to create one array of benefits
 * that includes the values of the efficiency goal benefit
 * @param benefits
 * @returns
 */
export const mergeBenefits = (
  benefits: ProjectGoalBenefit[]
): ProjectGoalBenefit[] => {
  return Object.keys(BenefitType).map((key) => {
    const goalBenefit = benefits.find((item) => item.benefitType === key);

    return {
      benefitType: BenefitType[key as keyof typeof BenefitType],
      ...goalBenefit,
    } as ProjectGoalBenefit;
  });
};

export const calculateProjectEffectRealisationPerYear = (
  projects: ProjectEffectRealisation[]
): ProjectEffectRealisationPerYear[] => {
  const perYear = [] as ProjectEffectRealisationPerYear[];

  projects.forEach((project) => {
    const maxYear =
      project.maxTakeHomePeriod && project.endYear
        ? project.maxTakeHomePeriod + project.endYear
        : project.endYear;

    const noOfYears = project.endYear
      ? maxYear
        ? maxYear - project.endYear
        : 1
      : 0;

    if (maxYear && project.endYear && noOfYears > 0) {
      // Calculate the benefits per year
      const benefitsByYear =
        project.benefits?.map((benefit) => ({
          ...benefit,
          expected: (benefit.expected ?? 0) / noOfYears,
          actual: (benefit.actual ?? 0) / noOfYears,
        })) ?? [];

      for (let i = project.endYear; i <= maxYear; i++) {
        const index = perYear.findIndex((p) => p.year === i);

        if (index !== -1) {
          perYear[index] = {
            year: i,
            benefits: benefitsByYear?.map((benefit, index) => {
              const existing = perYear[index]?.benefits?.find(
                (b) => b.benefitType === benefit.benefitType
              );

              return {
                actual: (benefit.actual ?? 0) + (existing?.actual ?? 0),
                expected: (benefit.expected ?? 0) + (existing?.expected ?? 0),
                benefitType: benefit.benefitType,
              };
            }),
            totalActualBenefit:
              (perYear[index].totalActualBenefit +
                (project.totalActualBenefit ?? 0)) /
              noOfYears,
            totalExpectedBenefit:
              (perYear[index].totalExpectedBenefit +
                (project.totalExpectedBenefit ?? 0)) /
              noOfYears,
            totalBudget:
              (perYear[index].totalBudget + (project.budget ?? 0)) / noOfYears,
            deviation: 0,
          };
        } else {
          perYear.push({
            year: i,
            benefits: benefitsByYear,
            totalActualBenefit: (project.totalActualBenefit ?? 0) / noOfYears,
            totalExpectedBenefit:
              (project.totalExpectedBenefit ?? 0) / noOfYears,
            totalBudget: (project.budget ?? 0) / noOfYears,
            deviation: 0,
          });
        }
      }
    }

    perYear.forEach((year) => {
      year.deviation = year.totalActualBenefit - year.totalExpectedBenefit;
    });
  });

  return perYear;
};

export const calculateProjectEffectRealisation = (
  projects: ProjectEffectRealisation[]
): ProjectEffectRealisation[] => {
  return projects.map((project) => {
    const totalActualBenefit =
      project.benefits?.reduce(
        (acc, benefit) => (acc += benefit.actual ?? 0),
        0
      ) ?? 0;

    const totalExpectedBenefit =
      project.benefits?.reduce(
        (acc, benefit) => (acc += benefit.expected ?? 0),
        0
      ) ?? 0;

    return {
      ...project,
      totalActualBenefit,
      totalExpectedBenefit,
      deviation: totalActualBenefit - totalExpectedBenefit,
      takeHomePeriodEndYear:
        project.endYear && project.maxTakeHomePeriod
          ? project.endYear + project.maxTakeHomePeriod
          : undefined,
    } as ProjectEffectRealisation;
  });
};

export const calculateGoalEffectRealisation = (
  goals: ProjectGoal[]
): ProjectGoal[] => {
  return goals?.map((goal) => {
    const totalActualBenefit =
      goal.benefits?.reduce(
        (acc, benefit) => (acc += benefit.actual ?? 0),
        0
      ) ?? 0;

    const totalExpectedBenefit =
      goal.benefits?.reduce(
        (acc, benefit) => (acc += benefit.expected ?? 0),
        0
      ) ?? 0;

    return {
      ...goal,
      totalActualBenefit,
      totalExpectedBenefit,
      deviation: totalActualBenefit - totalExpectedBenefit,
    } as ProjectGoal;
  });
};

const mapBenefitsPerYear = (
  projects: ProjectEffectRealisationPerYear[],
  property: 'actual' | 'expected'
) => {
  return projects
    .map((project) => ({
      ...project,
      finance:
        project.benefits?.find((b) => b.benefitType === BenefitType.Finance)?.[
          property
        ] ?? 0,
      redistribution:
        project.benefits?.find(
          (b) => b.benefitType === BenefitType.Redistribution
        )?.[property] ?? 0,
      quality:
        project.benefits?.find((b) => b.benefitType === BenefitType.Quality)?.[
          property
        ] ?? 0,
      environment:
        project.benefits?.find(
          (b) => b.benefitType === BenefitType.Environment
        )?.[property] ?? 0,
      other:
        project.benefits?.find((b) => b.benefitType === BenefitType.Other)?.[
          property
        ] ?? 0,
      totalExpectedBenefit:
        project.benefits?.reduce((acc, b) => acc + (b.expected ?? 0), 0) ?? 0,
      totalActualBenefit:
        project.benefits?.reduce((acc, b) => acc + (b.actual ?? 0), 0) ?? 0,
    }))
    .sort((a, b) => a.year - b.year);
};

export const mapActualBenefitsPerYear = (
  projects: ProjectEffectRealisationPerYear[]
) => mapBenefitsPerYear(projects, 'actual');

export const mapExpectedBenefitsPerYear = (
  projects: ProjectEffectRealisationPerYear[]
) => mapBenefitsPerYear(projects, 'expected');
