import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  piosActions,
  selectFilterByArchive,
  selectSelectedProjectIdea,
} from 'api/piosSlice';
import { useGetArchiveOverviewQuery } from 'api/project/projectIdeaOverviewApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CommonRoles, Permission, ProjectIdeaAction } from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { hasPermission, isAccountAdmin } from 'utils';
import { IdeaArchiveList } from './components/IdeaArchiveList';
import { IdeaArchiveListEditButtons } from './components/IdeaArchiveListEditButtons';
import { IdeaView } from './components/IdeaView';

export const IdeaArchive = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilterByArchive);
  const selectedIdea = useAppSelector(selectSelectedProjectIdea);

  const location = useLocation();
  const searchedIdeaId = (location?.state as Record<string, string>)
    ?.ideaId as string;
  const [selectedIdeaId, setSelectedIdeaId] = useState<string>(
    searchedIdeaId || ''
  );

  const { projectId = '' } = useParams<{
    projectId: string;
  }>();

  const {
    data: ideas,
    isFetching,
    isLoading,
  } = useGetArchiveOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter,
          _userId: accessToken.user.id,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  useEffect(() => {
    if (!projectId) {
      dispatch(piosActions.setSelectedProjectIdea(undefined));
    }

    if (projectId && ideas) {
      const selected = ideas.find((p) => p.projectId === projectId);

      dispatch(piosActions.setSelectedProjectIdea({ ...selected }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideas, projectId]);

  useEffect(() => {
    if (selectedIdeaId && accessToken.customerId) {
      const idea = ideas?.find((idea) => idea.id === searchedIdeaId);
      if (idea) {
        dispatch(
          piosActions.setSelectedProjectIdea({
            ...idea,
          })
        );
        setSelectedIdeaId('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIdeaId, ideas]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('FutureProjects.ProjectIdeaArchive.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters
            isTagFilterShown={true}
            isPhaseFilterShown={false}
            isStatusFilterShown={false}
            isResetFilterShown={true}
            isPeriodFilterShown={true}
            isIncludeMineOnlyFilterShown={
              // This is only relevant for admins and researchers
              // everyone else can only see their own project ideas
              isAccountAdmin(accessToken) ||
              accessToken.isInRole([CommonRoles.Researcher])
            }
            isCustomDimensionFiltersShown={true}
            isPortfolioFilterShown={true}
            isProject={false}
          />
        </CardBody>
      </Card>

      {ideas && (
        <Card>
          <CardBody isLoading={isFetching || isLoading}>
            {selectedIdea && (
              <IdeaView
                showEditButtons={false}
                backAction={ProjectIdeaAction.Archive}
              />
            )}

            {!selectedIdea && <IdeaArchiveList projects={ideas} />}
          </CardBody>
        </Card>
      )}

      {selectedIdea && hasPermission(Permission.EditIdea, selectedIdea) && (
        <IdeaArchiveListEditButtons
          idea={selectedIdea}
          showAsFooterButton={true}
        />
      )}
    </>
  );
};

IdeaArchive.displayName = 'IdeaArchive';
