import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { BadgeLarge, BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  CardFocused,
  CardFocusedBody,
  CardTile,
  ViewHeader,
  ViewHeaderActions,
  ViewRowBreak,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetCalculationsQuery } from 'api/calculation/calculationApi';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import { useGetProjectIdeasOverviewQuery } from 'api/project/projectIdeaOverviewApi';
import {
  Dimension,
  DimensionDisplayType,
  DimensionType,
  Phase,
  ProjectIdea as ProjectIdeaModel,
} from 'models';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft, BsX } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { calculateProjectIdeasQuota } from 'utils/dimension';
import { ProjectIdeaClassificationsList } from './components/ProjectIdeaClassificationsList';
import { ProjectIdeaData } from './components/ProjectIdeaData';
import { ProjectIdeaQualifyList } from './components/ProjectIdeaQualifyList';

export const ProjectIdea = (): JSX.Element | null => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { id = '', projectId = '' } = useParams();
  const { accessToken } = useContext(AuthenticationContext);

  const [idea, setIdea] = useState<ProjectIdeaModel>();
  const [filteredDimensions, setFilteredDimensions] = useState<Dimension[]>();
  const [classifications, setClassifications] = useState<Dimension[]>([]);

  const {
    data: customDimensions,
    isLoading: isLoadingDimensions,
    isFetching: isFetchingDimensions,
  } = useGetDimensionsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const {
    data: calculations,
    isLoading: isLoadingCalculations,
    isFetching: isFetchingCalculations,
  } = useGetCalculationsQuery(
    accessToken.customerId ? accessToken.customerId : skipToken
  );

  const {
    data,
    isFetching: isFetchingIdeas,
    isLoading: isLoadingIdeas,
  } = useGetProjectIdeasOverviewQuery(
    accessToken.customerId && accessToken.user?.id && projectId
      ? {
          filter: { ideaId: projectId },
          _userId: accessToken.user.id,
          _customerId: accessToken.customerId,
        }
      : skipToken
  );

  const isLoading =
    isFetchingIdeas ||
    isLoadingIdeas ||
    isFetchingDimensions ||
    isLoadingDimensions ||
    isFetchingCalculations ||
    isLoadingCalculations;

  const handleCancel = () => {
    navigate(`/project/${id}/${projectId}`);
  };

  useEffect(() => {
    setIdea(data?.[0]);
  }, [data]);

  useEffect(() => {
    setFilteredDimensions(
      customDimensions?.filter(
        (dimension) =>
          dimension.projectPhases?.includes(Phase.Draft) &&
          !dimension.projectPhases?.includes(Phase.NotSet) &&
          dimension.projectPhases.length > 0 &&
          dimension.type === DimensionType.Data
      )
    );

    if (customDimensions) {
      const dimensions = customDimensions
        .filter(
          (dimension) =>
            (dimension.type === DimensionType.ClassificationBenefit ||
              dimension.type === DimensionType.ClassificationStake) &&
            dimension.projectPhases?.includes(Phase.Draft)
        )
        .sort((_a, b) =>
          b.type === DimensionType.ClassificationBenefit ? 1 : -1
        )
        .sort((_a, b) =>
          b.displayType === DimensionDisplayType.Rating ? 1 : -1
        );

      setClassifications(dimensions);
    }
  }, [customDimensions]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setIdea(data?.[0]);

    if (classifications.length === 0) {
      return;
    }

    const calculated = calculateProjectIdeasQuota({
      ideas: data,
      dimensions: classifications,
      calculations,
    });

    setIdea(calculated[0]);
  }, [data, classifications, calculations]);

  if (isLoading) {
    return <Loader />;
  }

  if (!idea) {
    return null;
  }

  return (
    <>
      <ViewHeader>
        <div>
          <TooltipContent id="tt-navigate-back" text={t('Common.UI.Back')} />
          <BadgeSmall
            data-tip
            data-for="tt-navigate-back"
            onClick={handleCancel}
            $inverted={true}
            style={{ marginRight: 15 }}
          >
            <BsArrowLeft />
          </BadgeSmall>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Heading1>
            {t('FutureProjects.ProjectIdea.ProjectIdeaFor', {
              name: idea?.title,
            })}
          </Heading1>
          {idea && (
            <ButtonLink onClick={handleCancel}>{idea.portfolioName}</ButtonLink>
          )}
        </div>

        <ViewHeaderActions>
          <div
            style={{
              display: 'flex',
              gap: 10,
            }}
          >
            <TooltipContent id="tt-cancel" text={t('Common.Form.Cancel')} />

            <BadgeLarge
              data-tip
              data-for="tt-cancel"
              onClick={handleCancel}
              $inverted={true}
            >
              <BsX />
            </BadgeLarge>
          </div>
        </ViewHeaderActions>

        <ViewRowBreak style={{ margin: 0 }} />
      </ViewHeader>

      <CardFocused>
        <CardFocusedBody isLoading={isLoading}>
          <Style>
            <CardTile>
              <ProjectIdeaData
                idea={idea}
                classifications={classifications}
                calculations={calculations}
                dimensions={filteredDimensions}
              />
            </CardTile>

            <CardTile>
              <ProjectIdeaQualifyList
                idea={idea}
                classifications={classifications}
                calculations={calculations}
              />
            </CardTile>

            <ProjectIdeaClassificationsList
              idea={idea}
              classifications={classifications}
            />
          </Style>
        </CardFocusedBody>
      </CardFocused>
    </>
  );
};

ProjectIdea.displayName = 'ProjectIdea';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 15px;
`;
