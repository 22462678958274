import { CacheTags, piosApi } from 'api/piosApi';
import { filterToProjectIdeaQuery } from 'api/utils';
import { PiosFilter, ProjectIdea } from 'models';
import { calculateNetBenefit, calculateYearlyNetBenefit } from 'utils';

const projectIdeaOverviewApi = piosApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get project ideas overview
     */
    getProjectIdeasOverview: build.query<
      ProjectIdea[],
      {
        filter: PiosFilter;
        _userId: string | undefined;
        _customerId: string | undefined;
      }
    >({
      // _customerId and  _userId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switched
      query: ({ filter, _userId, _customerId }) => {
        const url = `overview/ideas?pageNumber=1&pageSize=10000&orderBy.propertyName=title&orderBy.direction=Ascending`;

        const filters = filterToProjectIdeaQuery(filter);

        return `${url}${filters}`;
      },
      transformResponse: (result: { records: ProjectIdea[] }, _meta, arg) => {
        result.records.forEach((project) => {
          project.netBenefit = calculateNetBenefit(project);
          project.netBenefitYearly = calculateYearlyNetBenefit(project);
          project.id = project.projectId;
          project.totalBudgetPeriod = !arg.filter.period
            ? undefined
            : project.periods?.find(
                (period) => period.year === arg.filter.period
              )?.totalBudget ?? undefined;
        });

        return result.records.sort((a, b) => {
          if (a.budget === b.budget) {
            return (a.title as string) < (b.title as string) ? 1 : -1;
          }

          return (a.budget ?? 0) < (b.budget ?? 0) ? 1 : -1;
        });
      },
      providesTags: [CacheTags.ProjectIdeaOverview],
    }),

    /**
     * Get archive
     */

    getArchiveOverview: build.query<
      ProjectIdea[],
      {
        filter: PiosFilter;
        _userId: string | undefined;
        _customerId: string | undefined;
      }
    >({
      // _customerId and  _userId is only used for RTK-Q to know that it should invalidate
      // the cache and re-fetch when the user is switched
      query: ({ filter, _userId, _customerId }) => {
        const url = `overview/archive?pageNumber=1&pageSize=10000&orderBy.propertyName=title&orderBy.direction=Ascending`;

        const filters = filterToProjectIdeaQuery(filter);

        return `${url}${filters}`;
      },
      transformResponse: (result: { records: ProjectIdea[] }, _meta, arg) => {
        result.records.forEach((project) => {
          project.netBenefit = calculateNetBenefit(project);
          project.netBenefitYearly = calculateYearlyNetBenefit(project);
          project.id = project.projectId;
          project.totalBudgetPeriod = !arg.filter.period
            ? undefined
            : project.periods?.find(
                (period) => period.year === arg.filter.period
              )?.totalBudget ?? undefined;
        });

        return result.records.sort((a, b) => {
          if (a.budget === b.budget) {
            return (a.title as string) < (b.title as string) ? 1 : -1;
          }

          return (a.budget ?? 0) < (b.budget ?? 0) ? 1 : -1;
        });
      },
      providesTags: [CacheTags.ProjectIdeaOverviewArchive],
    }),
  }),
});

export const { useGetProjectIdeasOverviewQuery, useGetArchiveOverviewQuery } =
  projectIdeaOverviewApi;
