import { AccountAdmins } from '@dimatech/features-core/lib/features/administrate-account/components/AccountAdmins';
import { ApiKey } from '@dimatech/features-core/lib/features/administrate-account/components/ApiKey';
import { BillingInfo } from '@dimatech/features-core/lib/features/administrate-account/components/BillingInfo';
import { LocaleInfo } from '@dimatech/features-core/lib/features/administrate-account/components/LocaleInfo';
import { Logo } from '@dimatech/features-core/lib/features/administrate-account/components/Logo';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PublishedProjectsCriteria } from './components/PublishedProjectsCriteria';
import { UpgradeLicense } from './components/UpgradeLicense';

export const AdministrateAccount = (): JSX.Element | null => {
  const { t } = useTranslation();

  const { accessToken } = useContext(AuthenticationContext);

  const isPublicApiEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublicApiEnabled];
  const isLocaleSelectionEnabledFlagOn =
    useFlags()[flags.permanent.shared.isLocaleSelectionEnabled];
  const isBaseLicenseEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isBaseLicenseEnabled];

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrate.Account.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <AccountAdmins />
        </CardBody>
      </Card>

      {isLocaleSelectionEnabledFlagOn && (
        <Card>
          <CardBody>
            <LocaleInfo />
          </CardBody>
        </Card>
      )}

      <Card>
        <CardBody>
          <BillingInfo />
        </CardBody>
      </Card>

      {isPublicApiEnabledFlagOn && !accessToken.isBaseLicense && (
        <Card>
          <CardBody>
            <ApiKey />
          </CardBody>
        </Card>
      )}

      {accessToken.isBaseLicense && isBaseLicenseEnabledFlagOn && (
        <Card>
          <CardBody>
            <UpgradeLicense />
          </CardBody>
        </Card>
      )}

      <Card>
        <CardBody>
          <PublishedProjectsCriteria />
        </CardBody>
      </Card>

      {accessToken.isInRole(CommonRoles.GlobalAdmin) && (
        <Card>
          <CardBody>
            <Logo customerId={accessToken.customerId} />
          </CardBody>
        </Card>
      )}
    </>
  );
};

AdministrateAccount.displayName = 'AdministrateAccount';
