import { useGetEntitiesQuery } from '@dimatech/features-core/lib/api/entity/entityApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { getEntitiesInPath } from '@dimatech/features-core/lib/utils';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
  ViewRow,
} from '@dimatech/shared/lib/components/workspace';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { selectFilterByEffectRealisation } from 'api/piosSlice';
import { useGetProjectEffectRealisationsOverviewQuery } from 'api/project/projectEffectRealisationOverviewApi';
import { SelectExtendedFilters } from 'components/SelectExtendedFilters';
import { useAppSelector } from 'hooks';
import {
  Phase,
  ProjectEffectRealisation,
  ProjectEffectRealisationAdditionalData,
  ProjectEffectRealisationPerYear,
} from 'models';
import { useContext, useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  calculateProjectEffectRealisationPerYear,
  mapActualBenefitsPerYear,
  mapExpectedBenefitsPerYear,
} from 'utils/project-effect';
import { ProjectsActualBenefitOverTime } from './components/ProjectsActualBenefitOverTime';
import { ProjectsExpectedBenefitOverTime } from './components/ProjectsExpectedBenefitOverTime';
import { ProjectsList } from './components/ProjectsList';
import { ProjectsStatistics } from './components/ProjectsStatistics';

export const ProjectsBenefits = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilterByEffectRealisation);

  const saveToPngElementId = useId();

  const [shownProjects, setShownProjects] = useState<
    ProjectEffectRealisation[]
  >([]);
  const [projects, setProjects] = useState<ProjectEffectRealisation[]>();
  const [additionalData, setAdditionalData] =
    useState<ProjectEffectRealisationAdditionalData>();
  const [maxValue, setMaxValue] = useState(0);

  const [projectsPerYear, setProjectsPerYear] =
    useState<ProjectEffectRealisationPerYear[]>();
  const [projectsExpectedPerYear, setProjectsExpectedPerYear] =
    useState<ProjectEffectRealisationPerYear[]>();

  const {
    data,
    isFetching: isFetchingProjects,
    isLoading: isLoadingProjects,
  } = useGetProjectEffectRealisationsOverviewQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          filter: {
            ...filter,
          },
          _customerId: accessToken.customerId,
          _userId: accessToken.user.id,
        }
      : skipToken
  );

  const {
    data: entities,
    isFetching: isFetchingEntities,
    isLoading: isLoadingEntities,
  } = useGetEntitiesQuery(
    accessToken.customerId && accessToken.user?.id
      ? {
          _customerId: accessToken.customerId,
          _userId: accessToken.user?.id,
        }
      : skipToken
  );

  const isLoading =
    isLoadingProjects ||
    isFetchingProjects ||
    isLoadingEntities ||
    isFetchingEntities;

  useEffect(() => {
    if (!entities || !data) {
      return;
    }

    const entitiesInPath = getEntitiesInPath(entities, null);

    const mapped = data.projects.map((project) => ({
      ...project,
      entityPath: entitiesInPath.find(
        (entity) => project.entityId === entity.id
      )?.path,
    }));

    setAdditionalData(data.additionalData);
    setProjects(mapped);
    setShownProjects(mapped);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities, data]);

  useEffect(() => {
    if (!shownProjects) {
      return;
    }

    const data = shownProjects.reduce(
      (data, project) => {
        return {
          ...data,
          totalActualBenefit:
            data.totalActualBenefit + (project.totalActualBenefit ?? 0),
          totalExpectedBenefit:
            data.totalExpectedBenefit +
            (project.projectPhase !== Phase.FinishedEffectRealizationConcluded
              ? project.totalExpectedBenefit ?? 0
              : 0),
          totalBudget: data.totalBudget + (project.budget ?? 0),
        };
      },
      {
        totalBudget: 0,
        totalExpectedBenefit: 0,
        totalActualBenefit: 0,
        numberOfGoals: 0,
        averageTakeHomePeriod: 0,
        maxTakeHomePeriod: 0,
      }
    );

    setAdditionalData(data);

    const perYear = calculateProjectEffectRealisationPerYear(shownProjects);
    const perYearExpected = calculateProjectEffectRealisationPerYear(
      shownProjects.filter(
        (project) =>
          project.projectPhase !== Phase.FinishedEffectRealizationConcluded
      )
    );

    const mappedPerYearExpected = mapExpectedBenefitsPerYear(perYearExpected);
    const mappedPerYear = mapActualBenefitsPerYear(perYear);

    const maxValue =
      Math.ceil(
        (Math.max(
          ...mappedPerYear.map((p) => p.totalExpectedBenefit),
          ...mappedPerYear.map((p) => p.totalActualBenefit),
          ...mappedPerYearExpected.map((p) => p.totalActualBenefit),
          ...mappedPerYearExpected.map((p) => p.totalActualBenefit)
        ) *
          1.1) /
          10000
      ) * 10000;

    setMaxValue(maxValue);
    setProjectsExpectedPerYear(mappedPerYearExpected);
    setProjectsPerYear(mappedPerYear);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownProjects]);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('ImplementedProjects.ProjectsBenefits.Title')}</Heading1>
      </ViewHeader>

      <Card>
        <CardBody>
          <SelectExtendedFilters
            isTagFilterShown={true}
            isPhaseFilterShown={true}
            isStatusFilterShown={true}
            isResetFilterShown={true}
            isPeriodFilterShown={false}
            isPeriodRangeFilterShown={true}
            isIncludeMineOnlyFilterShown={false}
            isProjectEffectTakeHomeFilterShown={false}
            isCustomDimensionFiltersShown={false}
            isProjectEffectRealisationPhasesFilterShown={true}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody style={{ padding: 0 }} isLoading={isLoading}>
          {shownProjects && (
            <ProjectsStatistics
              projects={shownProjects}
              additionalData={additionalData}
            />
          )}
        </CardBody>
      </Card>

      <Style>
        <Card
          title={t(
            'ImplementedProjects.ProjectsBenefits.ProjectsActualBenefitOverTime.Title'
          )}
          helpText={t(
            'ImplementedProjects.ProjectsBenefits.ProjectsActualBenefitOverTime.Help',
            {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            }
          )}
          canBeSavedAsPng={true}
        >
          <CardBody isLoading={isLoading}>
            {projectsPerYear && (
              <ProjectsActualBenefitOverTime
                projectsPerYear={projectsPerYear}
                maxValue={maxValue}
              />
            )}
          </CardBody>
        </Card>

        <Card
          title={t(
            'ImplementedProjects.ProjectsBenefits.ProjectsExpectedBenefitOverTime.Title'
          )}
          helpText={t(
            'ImplementedProjects.ProjectsBenefits.ProjectsExpectedBenefitOverTime.Help',
            {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            }
          )}
          canBeSavedAsPng={true}
        >
          <CardBody isLoading={isLoading}>
            {projectsExpectedPerYear && (
              <ProjectsExpectedBenefitOverTime
                projectsPerYear={projectsExpectedPerYear}
                maxValue={maxValue}
              />
            )}
          </CardBody>
        </Card>
      </Style>

      <Card
        title={t('ImplementedProjects.ProjectsBenefits.Projects.Title')}
        canBeSavedAsPng={true}
        canBeSavedAsCsv={true}
        saveToPngElementId={saveToPngElementId}
      >
        <CardBody isLoading={isLoading}>
          {projects && (
            <ProjectsList
              projects={projects}
              shownProjects={shownProjects}
              setShownProjects={setShownProjects}
              saveToPngElementId={saveToPngElementId}
            />
          )}
        </CardBody>
      </Card>
    </>
  );
};

ProjectsBenefits.displayName = 'ProjectsBenefits';

const Style = styled(ViewRow)`
  width: 100%;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;

  > div {
    flex-basis: 300px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  > div:first-of-type {
    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 1;
  }

  > div:last-of-type {
    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;
