import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Phase,
  PiosFilter,
  ProjectDimension,
  ProjectIdea,
  ProjectIdeaAction,
  ProjectStatus,
} from 'models';
import { RootState } from 'store';

type State = {
  filter: PiosFilter;
  selectedProjectIdea?: ProjectIdea;
  selectedProjectIdeaAction: ProjectIdeaAction;
  hasUnsavedChanges: boolean;
  filterHasChanges: boolean;
};

const initialState: State = {
  filter: {
    phases: [
      Phase.NotStartedDecided,
      Phase.Paused,
      Phase.Started,
      Phase.NotSet,
    ],
    phasesEffectRealisation: [
      Phase.Finished,
      Phase.FinishedEffectRealizationStarted,
      Phase.FinishedEffectRealizationConcluded,
    ],
    phasesIdea: [
      Phase.Draft,
      Phase.Published,
      Phase.Ready,
      Phase.ReadyForReview,
    ],
  },
  selectedProjectIdeaAction: ProjectIdeaAction.Identify,
  hasUnsavedChanges: false,
  filterHasChanges: false,
};

export const { reducer: piosReducer, actions: piosActions } = createSlice({
  name: 'pios',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<PiosFilter>) => {
      state.filter = action.payload;
    },
    setFilterEntityIds: (state, action: PayloadAction<string[]>) => {
      state.filter.entityIds = action.payload;
    },
    setFilterPortfolioIds: (state, action: PayloadAction<string[]>) => {
      state.filter.portfolioIds = action.payload;
    },
    setFilterTagIds: (state, action: PayloadAction<string[]>) => {
      state.filter.tagIds = action.payload;
    },
    setFilterPeriod: (state, action: PayloadAction<number | undefined>) => {
      state.filter.period = action.payload;
    },
    setFilterPeriodMonth: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.filter.periodMonth = action.payload;
    },
    setFilterPeriodRange: (
      state,
      action: PayloadAction<{ from?: number; to?: number } | undefined>
    ) => {
      state.filter.periodRange = action.payload;
    },
    setFilterPhase: (state, action: PayloadAction<Phase[] | null>) => {
      state.filter.phases = action.payload;
    },
    setFilterPhaseEffectRealisation: (
      state,
      action: PayloadAction<Phase[] | null>
    ) => {
      state.filter.phasesEffectRealisation = action.payload;
    },
    setFilterIdeaStatus: (state, action: PayloadAction<Phase[] | null>) => {
      state.filter.phasesIdea = action.payload;
    },
    setFilterDimensions: (
      state,
      action: PayloadAction<ProjectDimension[] | null>
    ) => {
      state.filter.dimensions = action.payload;
    },
    setFilterStatus: (state, action: PayloadAction<ProjectStatus | null>) => {
      state.filter.status = action.payload;
    },
    setIsProjectIdeasReviewersEnabledFlagOn: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filter.isProjectIdeasReviewersEnabledFlagOn = action.payload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
      state.filterHasChanges = false;
    },
    setSelectedProjectIdea: (
      state,
      action: PayloadAction<ProjectIdea | undefined>
    ) => {
      state.selectedProjectIdea = action.payload;
    },
    setSelectedProjectIdeaAction: (
      state,
      action: PayloadAction<ProjectIdeaAction | undefined>
    ) => {
      state.selectedProjectIdeaAction =
        action.payload ?? ProjectIdeaAction.Identify;
    },
    setFilterHasChanges: (state, action: PayloadAction<boolean>) => {
      state.filterHasChanges = action.payload;
    },
    setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
      state.hasUnsavedChanges = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(appActions.resetApplication, () => initialState);
  },
});

export const selectFilter = (state: RootState): PiosFilter => state.pios.filter;

export const selectSelectedProjectIdea = (
  state: RootState
): ProjectIdea | undefined => state.pios.selectedProjectIdea;

export const selectSelectedProjectIdeaAction = (
  state: RootState
): ProjectIdeaAction => state.pios.selectedProjectIdeaAction;

export const selectHasUnsavedChanges = (state: RootState): boolean =>
  state.pios.hasUnsavedChanges;

export const selectFilterByEffectRealisation = (state: RootState): PiosFilter =>
  ({
    ...state.pios.filter,
    portfolioIds: [],
    phases: [],
    phasesIdea: [],
    period: undefined,
    periodMonth: undefined,
    onlyMine: false,
    dimensions: null,
    isReadyForExport: undefined,
    hasEffectTakeHomePlan: undefined,
    isPublishedPublicly: undefined,
  } as PiosFilter);

export const selectFilterByIdea = (state: RootState): PiosFilter =>
  ({
    ...state.pios.filter,
    phases: [],
    phasesEffectRealisation: [],
    status: undefined,
  } as PiosFilter);

export const selectFilterByReadyForReview = (state: RootState): PiosFilter =>
  ({
    ...state.pios.filter,
    phases: [],
    phasesEffectRealisation: [],
    phasesIdea: [Phase.ReadyForReview],
    periodMonth: undefined,
    status: undefined,
  } as PiosFilter);

export const selectFilterByArchive = (state: RootState): PiosFilter =>
  ({
    ...state.pios.filter,
    phases: [],
    phasesEffectRealisation: [],
    phasesIdea: [],
    periodMonth: undefined,
    status: undefined,
  } as PiosFilter);

export const selectFilterHasChanges = (state: RootState): boolean =>
  state.pios.filterHasChanges;
