import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { BadgeLarge, BadgeSmall } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonFooterWithToast,
  ButtonLink,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { UnsavedChangesWarning } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1, Heading4 } from '@dimatech/shared/lib/components/typography';
import {
  CardFocused,
  CardFocusedBody,
  CardRow,
  CardTile,
  ViewHeader,
  ViewHeaderActions,
  ViewRowBreak,
} from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetDimensionsQuery } from 'api/dimension/dimensionApi';
import { useGetPortfolioOverviewQuery } from 'api/portfolio/portfolioOverviewApi';
import {
  useAddEffectRealisationResponsibleMutation,
  useDeleteEffectRealisationResponsibleMutation,
} from 'api/project/effectRealisationResponsibleApi';
import {
  useAddProjectMutation,
  useUpdateProjectMutation,
} from 'api/project/projectApi';
import {
  useAddGoalMutation,
  useDeleteGoalMutation,
  useGetGoalsQuery,
  useUpdateGoalMutation,
} from 'api/project/projectGoalApi';
import { useGetProjectIdeasOverviewQuery } from 'api/project/projectIdeaOverviewApi';
import {
  useAddManagerMutation,
  useDeleteManagerMutation,
} from 'api/project/projectManagerApi';
import {
  useAddOrderingPartyMutation,
  useDeleteOrderingPartyMutation,
} from 'api/project/projectOrderingPartyApi';
import { useGetProjectsOverviewQuery } from 'api/project/projectOverviewApi';
import { DebugInfo } from 'components/DebugInfo';
import { GoalEditList } from 'features/project-goal/components/GoalEditList';
import { GoalEditListEffectRealisation } from 'features/project-goal/components/GoalEditListEffectRealisation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  DimensionType,
  Permission,
  Phase,
  Project,
  ProjectDimension,
  ProjectDimensionMutation,
  ProjectGoal,
  Project as ProjectModel,
  ProjectPeriod,
} from 'models';
import { nanoid } from 'nanoid';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowLeft, BsX } from 'react-icons/bs';
import { FaSave } from 'react-icons/fa';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import {
  hasPermission,
  isPiosReadOnly,
  isProjectOngoing,
  isReadOnly,
  isValidEmail,
} from 'utils';
import { mergeDimensions } from 'utils/dimension';
import { EffectRealisationResponsibleList } from './components/EffectRealisationResponsibleList';
import { ProjectEdit as ProjectEditComponent } from './components/ProjectEdit';
import { ProjectEditBaseLicense } from './components/ProjectEditBaseLicense';
import { ProjectManagersList } from './components/ProjectManagersList';
import { ProjectOrderingPartiesList } from './components/ProjectOrderingPartiesList';

// TODO: Split code
/* eslint-disable max-lines-per-function */

export const ProjectEdit = withTheme(
  ({ theme }: { theme: Theme }): JSX.Element | null => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const { id = '', projectId = '' } = useParams();
    const { accessToken } = useContext(AuthenticationContext);
    const isViewReadOnly = isPiosReadOnly(accessToken);

    const isProjectEffectRealisationEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectEffectRealisationEnabled];
    const isProjectRolesEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectRolesEnabled];
    const isProjectRoleOrderingPartyEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isProjectRoleOrderingPartyEnabled];
    const isPublishPubliclyEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isPublishPubliclyEnabled];
    const isPublishPubliclyByDefaultEnabledFlagOn =
      useFlags()[flags.permanent.app.pios.isPublishPubliclyByDefaultEnabled];

    const [addProject, { isLoading: addPosting, error: addErrors }] =
      useAddProjectMutation();
    const [updateProject, { isLoading: updatePosting, error: updateErrors }] =
      useUpdateProjectMutation();
    const [addGoal, { isLoading: addGoalPosting }] = useAddGoalMutation();
    const [updateGoal, { isLoading: updateGoalPosting }] =
      useUpdateGoalMutation();
    const [deleteGoal, { isLoading: deleteGoalPosting }] =
      useDeleteGoalMutation();
    const [createManager] = useAddManagerMutation();
    const [deleteManager] = useDeleteManagerMutation();
    const [createEffectRealisationResponsible] =
      useAddEffectRealisationResponsibleMutation();
    const [deleteEffectRealisationResponsible] =
      useDeleteEffectRealisationResponsibleMutation();
    const [createOrderingParty] = useAddOrderingPartyMutation();
    const [deleteOrderingParty] = useDeleteOrderingPartyMutation();

    const [goals, setGoals] = useState<ProjectGoal[]>([]);
    const [project, setProject] = useState<ProjectModel>();
    const [dimensions, setDimensions] = useState<ProjectDimensionMutation[]>();
    const [periods, setPeriods] = useState<ProjectPeriod[]>();
    const [isChangesSaved, setIsChangesSaved] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isPeriodValid, setIsPeriodValid] = useState(true);
    const [isUnsavedChangesWarningShown, setIsUnsavedChangesWarningShown] =
      useState(false);
    const [managers, setManagers] = useState<string[]>();
    const [effectRealisationResponsible, setEffectRealisationResponsible] =
      useState<string[]>();
    const [orderingParties, setOrderingParties] = useState<string[]>();

    const [
      isViewLimitedAsEffectRealisationResponsible,
      setIsViewLimitedAsEffectRealisationResponsible,
    ] = useState(false);

    const {
      data: customDimensions,
      isLoading: isLoadingDimensions,
      isFetching: isFetchingDimensions,
    } = useGetDimensionsQuery(
      accessToken.customerId ? accessToken.customerId : skipToken
    );

    const phases = Object.keys(Phase).map((value) => value as Phase);
    const {
      data: projectsOverview,
      isLoading,
      isFetching,
    } = useGetProjectsOverviewQuery(
      accessToken.customerId && accessToken.user?.id && id
        ? {
            filter: {
              portfolioIds: [id],
              phases,
            },
            _customerId: accessToken.customerId,
            _userId: accessToken.user.id,
          }
        : skipToken
    );

    const {
      data: portfolio,
      isLoading: isLoadingPortfolio,
      isFetching: isFetchingPortfolio,
    } = useGetPortfolioOverviewQuery(
      accessToken.customerId && accessToken.user?.id && id
        ? {
            filter: { portfolioIds: [id] },
            _customerId: accessToken.customerId,
            _userId: accessToken.user.id,
          }
        : skipToken
    );

    const {
      data: projectGoals,
      isLoading: isLoadingGoals,
      isFetching: isFetchingGoals,
    } = useGetGoalsQuery(
      accessToken.customerId && project?.id
        ? {
            projectId: project.id,
            _customerId: accessToken.customerId,
          }
        : skipToken
    );

    const { data: projectIdeasOverview } = useGetProjectIdeasOverviewQuery(
      accessToken.customerId &&
        accessToken.user?.id &&
        project?.id &&
        project.projectPhase &&
        !isProjectOngoing(project.projectPhase)
        ? {
            filter: { ideaId: project.id },
            _userId: accessToken.user.id,
            _customerId: accessToken.customerId,
          }
        : skipToken
    );

    const isGoalPosting =
      updateGoalPosting || addGoalPosting || deleteGoalPosting;
    const isPosting = updatePosting || addPosting || isGoalPosting;

    const handleConfirmCancel = () => {
      if (hasChanges) {
        setIsUnsavedChangesWarningShown(true);
      } else {
        handleCancel();
      }
    };

    const handleCancel = () => {
      if (!project?.id && !accessToken.isBaseLicense) {
        navigate(
          (location.state as { from: string })?.from ??
            `/portfolio/${portfolio?.portfolioId}`
        );
        return;
      }

      if (!project?.id && accessToken.isBaseLicense) {
        navigate((location.state as { from: string })?.from ?? `/projects`);

        return;
      }

      navigate(`/project/${portfolio?.portfolioId}/${project?.id}`);
    };

    const handleSave = () => {
      setIsValid(true);
      setIsPeriodValid(true);

      if (isViewReadOnly || isPosting || !hasChanges || !project) {
        return;
      }

      if (!project?.title?.trim()) {
        setIsValid(false);
        return;
      }

      if (!!project.orderingParty && !isValidEmail(project.orderingParty)) {
        setIsValid(false);
        return;
      }

      if (
        goals.some(
          (goal) =>
            !goal.innovationEfficiencyClassification ||
            !goal.internalExternalClassification
        )
      ) {
        setIsValid(false);
        return;
      }

      if (
        project.startYear &&
        project.endYear &&
        project.startYear > project.endYear
      ) {
        setIsPeriodValid(false);
        return;
      }

      const newProject = {
        ...project,
        title: project.title.trim(),
        description: project.description?.trim(),
        benefitsAndEffects: project.benefitsAndEffects?.trim(),
        customDimensions:
          dimensions?.map(
            (dimension) =>
              ({
                projectDimensionId: dimension.projectDimensionId,
                value: dimension.value || null,
              } as ProjectDimension)
          ) ?? [],
        periods: periods ? [...periods] : [],
        projectManagers: managers,
        effectRealizationResponsible: effectRealisationResponsible,
        orderingParties,
        isPublishedPublicly:
          project.projectPhase === Phase.Draft ||
          project.projectPhase === Phase.Ready
            ? false
            : project.isPublishedPublicly,
      } as Project;

      if (project.id) {
        updateProject(newProject)
          .unwrap()
          .then(() => {
            addUpdateGoals(project);
            addUpdateManagers();
            addUpdateEffectRealisationResponsible();
            addUpdateOrderingParties();
          })
          .finally(() => {
            setHasChanges(false);
            setIsChangesSaved(true);
            navigate(`/project/${portfolio?.portfolioId}/${project.projectId}`);
          });
      } else {
        addProject({
          ...newProject,
          goals: [...goals],
        })
          .unwrap()
          .then((result) => {
            setHasChanges(false);
            setIsChangesSaved(true);

            navigate(`/project/${portfolio?.portfolioId}/${result.id}/edit`);
          });
      }
    };

    const addUpdateGoals = (project: Project) => {
      const goalsToAdd = goals.filter((goal) => !goal.id && goal.isDirty);
      const goalsToUpdate = goals.filter((goal) => goal.id && goal.isDirty);
      const goalsToDelete = projectGoals?.filter(
        (projectGoal) => !goals.find((goal) => goal.id === projectGoal.id)
      );

      goalsToAdd.forEach((goal) => {
        addGoal({ ...goal, projectId: project.id });
      });
      goalsToUpdate.forEach((goal) => {
        updateGoal({ ...goal });
      });
      goalsToDelete?.forEach((goal) => {
        deleteGoal({ ...goal });
      });
    };

    const addUpdateManagers = () => {
      const selected = projectsOverview?.projects.find(
        (p) => p.id === projectId
      ) as Project;
      const initialManagers = selected.projectManagers;

      const managersToAdd =
        managers?.filter((email) => !initialManagers?.includes(email)) ?? [];

      const managersToDelete =
        initialManagers?.filter((email) => !managers?.includes(email)) ?? [];

      if (
        project?.portfolioId &&
        project.id &&
        (managersToAdd.length > 0 || managersToDelete.length > 0)
      ) {
        createManager({
          portfolioId: project.portfolioId,
          projectId: project.id,
          projectUsers: managersToAdd,
        })
          .unwrap()
          .then(() => {
            if (managersToDelete.length > 0) {
              deleteManager({
                portfolioId: project.portfolioId as string,
                projectId: project.id as string,
                projectUsers: managersToDelete,
              });
            }
          });
      }
    };

    const addUpdateEffectRealisationResponsible = () => {
      const selected = projectsOverview?.projects.find(
        (p) => p.id === projectId
      ) as Project;

      const initialEffectRealisationResponsible =
        selected.effectRealizationResponsible;

      const effectRealisationResponsibleToAdd =
        effectRealisationResponsible?.filter(
          (email) => !initialEffectRealisationResponsible?.includes(email)
        ) ?? [];

      const effectRealisationResponsibleToDelete =
        initialEffectRealisationResponsible?.filter(
          (email) => !effectRealisationResponsible?.includes(email)
        ) ?? [];

      if (
        project?.portfolioId &&
        project.id &&
        (effectRealisationResponsibleToAdd.length > 0 ||
          effectRealisationResponsibleToDelete.length > 0)
      ) {
        createEffectRealisationResponsible({
          portfolioId: project.portfolioId,
          projectId: project.id,
          projectUsers: effectRealisationResponsibleToAdd,
        })
          .unwrap()
          .then(() => {
            if (effectRealisationResponsibleToDelete.length > 0) {
              deleteEffectRealisationResponsible({
                portfolioId: project.portfolioId as string,
                projectId: project.id as string,
                projectUsers: effectRealisationResponsibleToDelete,
              });
            }
          });
      }
    };

    const addUpdateOrderingParties = () => {
      const selected = projectsOverview?.projects.find(
        (p) => p.id === projectId
      ) as Project;
      const initialOrderingParties = selected.orderingParties;

      const orderingPartiesToAdd =
        orderingParties?.filter(
          (email) => !initialOrderingParties?.includes(email)
        ) ?? [];

      const orderingPartiesToDelete =
        initialOrderingParties?.filter(
          (email) => !orderingParties?.includes(email)
        ) ?? [];

      if (
        project?.portfolioId &&
        project.id &&
        (orderingPartiesToAdd.length > 0 || orderingPartiesToDelete.length > 0)
      ) {
        createOrderingParty({
          portfolioId: project.portfolioId,
          projectId: project.id,
          projectUsers: orderingPartiesToAdd,
        })
          .unwrap()
          .then(() => {
            if (orderingPartiesToDelete.length > 0) {
              deleteOrderingParty({
                portfolioId: project.portfolioId as string,
                projectId: project.id as string,
                projectUsers: orderingPartiesToDelete,
              });
            }
          });
      }
    };

    useEffect(() => {
      if (location.pathname.endsWith('new')) {
        setProject({
          portfolioId: id,
          permissions: [Permission.Edit, Permission.EditEffectRealization],
          projectPhase: Phase.NotSet,
          isPublishedPublicly:
            !!isPublishPubliclyEnabledFlagOn &&
            !!isPublishPubliclyByDefaultEnabledFlagOn,
        });
        return;
      }

      if (projectsOverview?.projects && projectId) {
        const selected = {
          ...projectsOverview.projects.find((p) => p.id === projectId),
        };

        setProject(selected);
        setPeriods(selected ? selected.periods : []);
      }
    }, [
      projectsOverview,
      projectId,
      location,
      id,
      customDimensions,
      isPublishPubliclyByDefaultEnabledFlagOn,
      isPublishPubliclyEnabledFlagOn,
    ]);

    useEffect(() => {
      if (project) {
        setIsViewLimitedAsEffectRealisationResponsible(
          !hasPermission(Permission.Edit, project) &&
            hasPermission(Permission.EditEffectRealization, project)
        );
      }
    }, [project, accessToken]);

    useEffect(() => {
      if (projectsOverview?.projects) {
        const selected = projectsOverview.projects.find(
          (p) => p.id === projectId
        );

        setManagers(selected?.projectManagers);
        setEffectRealisationResponsible(selected?.effectRealizationResponsible);
        setOrderingParties(selected?.orderingParties);

        if (
          customDimensions &&
          project?.projectPhase &&
          isProjectOngoing(project.projectPhase)
        ) {
          const dimensionMutations = mergeDimensions(
            customDimensions,
            true,
            selected,
            DimensionType.Data
          ).sort((a, b) =>
            (a.displayType ?? '') > (b.displayType ?? '') ? 1 : -1
          );

          setDimensions(dimensionMutations);
        }

        if (
          customDimensions &&
          project?.projectPhase &&
          !isProjectOngoing(project.projectPhase)
        ) {
          const idea = projectIdeasOverview?.[0];
          const dimensionMutations = mergeDimensions(
            customDimensions,
            false,
            idea
          );

          setDimensions(dimensionMutations);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      projectsOverview,
      projectId,
      location,
      id,
      customDimensions,
      project?.projectPhase,
      projectIdeasOverview,
    ]);

    useEffect(() => {
      setGoals(
        projectGoals?.map((goal) => ({
          ...goal,
          isDirty: false,
          uid: nanoid(6),
        })) ?? []
      );
    }, [projectGoals]);

    if (portfolio && project && isReadOnly(project)) {
      return (
        <Navigate to={`/project/${portfolio?.portfolioId}/${project?.id}`} />
      );
    }

    return (
      <>
        <ViewHeader>
          <div>
            <TooltipContent id="tt-navigate-back" text={t('Common.UI.Back')} />
            <BadgeSmall
              data-tip
              data-for="tt-navigate-back"
              onClick={handleConfirmCancel}
              $inverted={true}
              style={{ marginRight: 15 }}
            >
              <BsArrowLeft />
            </BadgeSmall>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Heading1>
              {!project?.id ? t('Project.Add.Title') : project?.title}
            </Heading1>
            {portfolio && !accessToken.isBaseLicense && (
              <ButtonLink onClick={handleConfirmCancel}>
                {portfolio.portfolioName}
              </ButtonLink>
            )}
          </div>

          <ViewHeaderActions>
            <div
              style={{
                display: 'flex',
                gap: 10,
              }}
            >
              <TooltipContent id="tt-cancel" text={t('Common.Form.Cancel')} />
              <TooltipContent id="tt-save" text={t('Common.Form.Save')} />

              <BadgeLarge
                data-tip
                data-for="tt-cancel"
                onClick={handleConfirmCancel}
                $inverted={true}
              >
                <BsX />
              </BadgeLarge>

              <BadgeLarge
                data-tip
                data-for="tt-save"
                onClick={handleSave}
                $inverted={true}
                disabled={isViewReadOnly || isPosting || !hasChanges}
                style={
                  hasChanges
                    ? {
                        backgroundColor: theme.colors.secondary,
                        color: theme.colors.onSecondary,
                      }
                    : undefined
                }
              >
                <FaSave />
              </BadgeLarge>
            </div>
          </ViewHeaderActions>

          <ViewRowBreak style={{ margin: 0 }} />
        </ViewHeader>

        {project && portfolio && (
          <CardFocused>
            <CardFocusedBody
              isLoading={
                isFetching ||
                isLoading ||
                isLoadingPortfolio ||
                isFetchingPortfolio ||
                isLoadingDimensions ||
                isFetchingDimensions
              }
            >
              <DebugInfo portfolio={portfolio} project={project} />

              <Style>
                {isUnsavedChangesWarningShown && (
                  <UnsavedChangesWarning
                    handleContinue={handleCancel}
                    handleCancel={() => setIsUnsavedChangesWarningShown(false)}
                  />
                )}

                {isValid && (
                  <AlertErrors
                    error={addErrors ?? updateErrors}
                    translationPath="Project.ValidationError"
                  />
                )}

                {!isPeriodValid && (
                  <AlertWarning style={{ margin: '20px 0' }}>
                    {t('Project.ValidationError.Period')}
                  </AlertWarning>
                )}

                {!accessToken.isBaseLicense ? (
                  <ProjectEditComponent
                    project={project}
                    dimensions={dimensions}
                    periods={periods}
                    setPeriods={setPeriods}
                    setProject={setProject}
                    setDimensions={setDimensions}
                    setHasChanges={setHasChanges}
                    isValid={isValid}
                  />
                ) : (
                  <ProjectEditBaseLicense
                    project={project}
                    setProject={setProject}
                    setHasChanges={setHasChanges}
                    isValid={isValid}
                  />
                )}
                {!accessToken.isBaseLicense && (
                  <>
                    <CardRow>
                      <CardTile isLoading={isLoadingGoals || isFetchingGoals}>
                        <Heading4 style={{ marginBottom: 10 }}>
                          {isProjectEffectRealisationEnabledFlagOn
                            ? `${t('Project.Goals.GoalsAndBenefits')}`
                            : `${t('Project.Goals.Goals')}`}
                        </Heading4>

                        {isProjectEffectRealisationEnabledFlagOn ? (
                          <GoalEditListEffectRealisation
                            project={project}
                            goals={goals}
                            setGoals={setGoals}
                            setHasChanges={setHasChanges}
                            isValid={isValid}
                          />
                        ) : (
                          <GoalEditList
                            project={project}
                            goals={goals}
                            setGoals={setGoals}
                            setHasChanges={setHasChanges}
                            isValid={isValid}
                          />
                        )}
                      </CardTile>
                    </CardRow>

                    {(isProjectRolesEnabledFlagOn ||
                      isProjectRoleOrderingPartyEnabledFlagOn) && (
                      <CardRow>
                        {isProjectRolesEnabledFlagOn && (
                          <>
                            <CardTile>
                              <ProjectManagersList
                                managers={managers}
                                setManagers={setManagers}
                                setHasChanges={setHasChanges}
                                isEditAvailable={
                                  !isViewLimitedAsEffectRealisationResponsible
                                }
                              />
                            </CardTile>

                            {isProjectEffectRealisationEnabledFlagOn && (
                              <CardTile>
                                <EffectRealisationResponsibleList
                                  effectRealisationResponsible={
                                    effectRealisationResponsible
                                  }
                                  setEffectRealisationResponsible={
                                    setEffectRealisationResponsible
                                  }
                                  setHasChanges={setHasChanges}
                                  isEditAvailable={
                                    !isViewLimitedAsEffectRealisationResponsible
                                  }
                                />
                              </CardTile>
                            )}
                          </>
                        )}

                        {isProjectRoleOrderingPartyEnabledFlagOn && (
                          <CardTile>
                            <ProjectOrderingPartiesList
                              orderingParties={orderingParties}
                              setOrderingParties={setOrderingParties}
                              setHasChanges={setHasChanges}
                              isEditAvailable={
                                !isViewLimitedAsEffectRealisationResponsible
                              }
                            />
                          </CardTile>
                        )}
                      </CardRow>
                    )}
                  </>
                )}

                <ButtonFooterWithToast
                  isSaved={isChangesSaved}
                  setIsSaved={setIsChangesSaved}
                >
                  <Buttons>
                    <ButtonSecondary
                      type="button"
                      onClick={handleConfirmCancel}
                    >
                      {t('Common.Form.Cancel')}
                    </ButtonSecondary>
                    <Button
                      type="button"
                      disabled={isViewReadOnly || isPosting || !hasChanges}
                      onClick={handleSave}
                    >
                      {t('Common.Form.Save')}
                    </Button>
                  </Buttons>
                </ButtonFooterWithToast>
              </Style>
            </CardFocusedBody>
          </CardFocused>
        )}
      </>
    );
  }
);

ProjectEdit.displayName = 'ProjectEdit';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 15px;
`;
