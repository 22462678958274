import { Phase } from 'models';

export const projectOngoingPhases = [
  Phase.NotStartedDecided,
  Phase.Started,
  Phase.Paused,
  Phase.Finished,
  Phase.FinishedEffectRealizationStarted,
  Phase.FinishedEffectRealizationConcluded,
  Phase.NotSet,
];

export const projectIdeaPhases = [
  Phase.Draft,
  Phase.Ready,
  Phase.Published,
  Phase.ReadyForReview,
];

export const isProjectOngoing = (phase?: Phase) => {
  if (!phase) {
    return false;
  }

  return projectOngoingPhases.includes(phase);
};

export const filterProjectIdeaStatus = (
  items: Phase[],
  isProjectIdeasReviewersEnabledFlagOn?: boolean
): Phase[] => {
  return items.filter((value) => {
    // Dont show Published status
    // if ProjectIdeasReviewers flag is on
    if (isProjectIdeasReviewersEnabledFlagOn && value === Phase.Published) {
      return false;
    }
    // Dont show ReadyForReview
    // if ProjectIdeasReviewers flag is off
    if (
      !isProjectIdeasReviewersEnabledFlagOn &&
      value === Phase.ReadyForReview
    ) {
      return false;
    }
    return true;
  });
};
